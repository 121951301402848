export const discountArray = [
  // {
  //   code: "BEF10",
  //   codePercentage: 0.9,
  // },
  {
    code: "DATA10",
    codePercentage: 0.9,
  },
  // {
  //   code: "SQL5",
  //   codePercentage: 0.95,
  // },
  // {
  //   code: "DATA20",
  //   codePercentage: 0.8,
  // },
  {
    code: "DSP60",
    codePercentage: 0.7,
  },
  {
    code: "FAST10",
    codePercentage: 0.9,
  },
  {
    code: "POWER15",
    codePercentage: 0.85,
  },
];
