import { createSlice } from "@reduxjs/toolkit";
import { SplitDate } from "../../utils/WebsiteTimer/WebsiteTimer";

export const timerSlice = createSlice({
  name: "countdowntimer",
  initialState: {
    countdownDate: ((nextCohortDate) => {
      const { month, year, day } = SplitDate(nextCohortDate);
      const date = new Date(year, month, day);
      return date.getTime() + (21 * 3600000); // Add 21 hours in milliseconds
    })("2025-3-3"),
    displayCohortDate: "",
  },
  reducers: {
    updateCountdownTimer: (state, action) => {
      state.countdownDate = new Date(action.payload).getTime();
    },
    updateDisplayCohortDate: (state, action) => {
      state.displayCohortDate = action.payload;
    },
  },
});

export const { updateCountdownTimer, updateDisplayCohortDate } =
  timerSlice.actions;

export default timerSlice.reducer;
